import React from 'react'

const cardsOption = [
    {
        xInterval: [-2, 2],
        yInterval: [-5, 5],
        scale: [100, 100],
        blur: 0
    },
    {
        xInterval: [-10, 10],
        yInterval: [90, 110],
        scale: [88, 92],
        blur: 0.04
    },
    {
        xInterval: [86, 106],
        yInterval: [-10, 10],
        scale: [88, 92],
        blur: 0.03
    },
    {
        xInterval: [-86, -106],
        yInterval: [-10, 10],
        scale: [88, 92],
        blur: 0.02
    },
    {
        xInterval: [-10, 10],
        yInterval: [-90, -110],
        scale: [88, 92],
        blur: 0.04
    },
    
    {
        xInterval: [-10, 10],
        yInterval: [-190, -210],
        scale: [83, 87],
        blur: 0.1
    },
    {
        xInterval: [80, 100],
        yInterval: [-90, -110],
        scale: [83, 87],
        blur: 0.12
    },

    {
        xInterval: [70, 90],
        yInterval: [90, 110],
        scale: [83, 87],
        blur: 0.1
    },
    {
        xInterval: [0, 20],
        yInterval: [190, 210],
        scale: [83, 87],
        blur: 0.11
    },
    {
        xInterval: [-70, -90],
        yInterval: [90, 110],
        scale: [83, 87],
        blur: 0.1
    },
    {
        xInterval: [-70, -90],
        yInterval: [-90, -110],
        scale: [83, 87],
        blur: 0.12
    },

    {
        xInterval: [80, 100],
        yInterval: [-200, -180],
        scale: [50, 70],
        blur: 0.2
    },
    {
        xInterval: [80, 100],
        yInterval: [200, 180],
        scale: [50, 70],
        blur: 0.3
    },
    {
        xInterval: [-100, -80],
        yInterval: [180, 200],
        scale: [50, 70],
        blur: 0.2
    },
    {
        xInterval: [-100, -80],
        yInterval: [-200, -180],
        scale: [50, 70],
        blur: 0.2
    },
]

function rdmNumb(min, max) {
    return Math.round( Math.random() * (max - min) + min )
}

function JobCardItem(props) {
    const { jobs, cardsOption } = props
    const url = 'https://www.linkedin.com/company/protonss4fun/'
    
    return cardsOption.map(
        (card, i) => {
            const randomCard = jobs[rdmNumb(0, jobs.length - 1)]
            return (
                <div
                    key={i}
                    className="p4f__jobs__cards__item"
                    style={{
                        transform: `translateX(${rdmNumb(card.xInterval[0], card.xInterval[1])}%)
                                    translateY(${rdmNumb(card.yInterval[0], card.yInterval[1])}%)
                                    scale(${rdmNumb(card.scale[0], card.scale[1]) * 0.01})`,
                        zIndex: cardsOption.length - i,
                        filter: `blur(${card.blur}em)`
                    }}
                    onClick={() => window.open(url, '_blank')}
                >
                    <p><strong>{randomCard.title}</strong></p>
                    <p>{randomCard.description}</p>
                </div>
            )
        }
    )
}

function JobCards(props) {
    return (
        <>
            <div className="p4f__jobs__title">
                <p><strong>#JoinUsP4F</strong></p>
                <p>Faça parte do <em>P4F Squad</em></p>
            </div>

            <div className="p4f__jobs__cards">
                <JobCardItem jobs={props.jobs} cardsOption={cardsOption} />
            </div>
        </>
    )
}

export default JobCards
