import React from 'react'
import styled, { keyframes } from 'styled-components'
import logo from '../media/img/logo-protons4fun.svg'

function Logo(props) {

    return (
        <LogoStyled className="p4f__home__logo">
            <img src={logo} alt="Protonss4Fun" />
        </LogoStyled>
    )
}

export default Logo

const logoEntry = keyframes`
    0% {
        opacity: 0;
        transform: translateY(40%) scale(0.1);
    }
    70% {
        opacity: 0.5;
        transform: translateY(0%) scale(.5);
    }
    100% {
        opacity: 1;
        transform: translateY(0%) scale(1);
    }
`

const LogoStyled = styled.figure`
    display: flex;
    justify-content: center;
    padding: 2em;

    img {
        display: block;
        width: 17em;
        animation: ${logoEntry} 0.5s linear forwards;
    }
`
