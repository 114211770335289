import { createGlobalStyle } from 'styled-components'

const GlobalStyledP4F = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    html,
    body,
    #root,
    .p4f {
        min-width: 100%;
        min-height: 100%;
        min-height: 100vh;
        font-family: 'Roboto', sans-serif;
    }
    .p4f {
        font-size: 14px;

        &__home {
            width: 100%;
            height: 100vh;
            overflow: hidden;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: rgb(240,249,254);
            background: radial-gradient(circle, rgba(240,249,254,1) 0%, rgba(192,212,223,1) 49%, rgba(156,192,201,1) 100%);
        }
        &__jobs {
            width: 100%;
            height: 100vh;
            display: grid;
            grid-template-rows: auto 1fr;
            background-color: #091F51;



            &__title {
                color: #FFF;
                text-align: center;
                padding: 4em;
                position: relative;
                z-index: 40;
                
                p {
                    font-family: 'Open Sans', sans-serif;
                    font-size: 1.6em;
                    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
                }
                strong {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 2em;
                }
            }
            &__cards {
                position: relative;


                &__item {
                    width: 26em;
                    margin: -6em 0 0 -13em;
                    padding: 1.5em;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    color: #4d5a5d;
                    border: 1px solid #FFF4;
                    border-bottom-color: #FFF2;
                    border-right-color: #FFF2;
                    background: rgb(240,249,254);
                    background: linear-gradient(135deg, rgba(240,249,254,1) 0%, rgba(192,212,223,1) 100%);
                    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.5);

                    &:hover {
                        cursor: pointer;
                        opacity: 0.9;
                    }

                    strong {
                        display: block;
                        font-size: 1.3em;
                        padding-bottom: 0.2em;
                    }
                }   
            }



        }
    }
`

export default GlobalStyledP4F
