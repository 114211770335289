import React from 'react'
import styled, { keyframes } from 'styled-components'

function SideFragments(props) {

    return (
        <SideFragmentsStyled className="p4f__home__side-fragment">
            <div className="p4f__home__side-fragment__p1" />
            <div className="p4f__home__side-fragment__p2" />
            <div className="p4f__home__side-fragment__p3" />
            <div className="p4f__home__side-fragment__p4" />
            <div className="p4f__home__side-fragment__p5" />
            <div className="p4f__home__side-fragment__p6" />
        </SideFragmentsStyled>
    )
}

export default SideFragments

const SideFragmentsStyled = styled.div`
    width: 100%;
    height:100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    
    & > div {
        position: absolute;
    }
   .p4f__home__side-fragment {
        &__p1 {
            background: rgb(176,230,122);
            background: linear-gradient(0deg, rgba(176,230,122,1) 0%, rgba(126,180,74,1) 49%, rgba(149,196,98,1) 100%);
            width: 40%;
            height: 40%;
            top: -18%;
            left: -8%;
            transform: rotate(-10deg);
            border-right: 1px solid #FFF5;
            border-bottom: 1px solid #0001;
            box-shadow: 0 0 30px rgba(0, 0, 0, .2);
        }
        &__p2 {
            background: rgb(24,86,138);
            background: linear-gradient(0deg, rgba(41,111,170,1) 0%, rgba(11,102,176,1) 42%, rgba(24,86,138,1) 100%);
            width: 25%;
            height: 16%;
            top: -8%;
            left: -6%;
            transform: rotate(5deg);
            border-right: 1px solid #FFF5;
            border-bottom: 1px solid #0001;
            box-shadow: 0 0 10px rgba(0, 0, 0, .4);
        }
        &__p3 {
            background: rgb(41,51,78);
            background: linear-gradient(0deg, rgba(98,106,128,1) 0%, rgba(54,61,79,1) 42%, rgba(41,51,78,1) 100%);
            width: 15%;
            height: 15%;
            top: -10%;
            left: -6%;
            transform: rotate(-5deg);
            border-right: 1px solid #FFF5;
            border-bottom: 1px solid #0001;
            box-shadow: 0 0 30px rgba(0, 0, 0, .4);
        }
        
        &__p4 {
            background: rgb(176,230,122);
            background: linear-gradient(0deg, rgba(176,230,122,1) 0%, rgba(126,180,74,1) 49%, rgba(149,196,98,1) 100%);
            width: 40%;
            height: 40%;
            bottom: -18%;
            right: -8%;
            transform: rotate(-10deg);
            border-top: 1px solid #FFF5;
            border-left: 1px solid #0001;
            box-shadow: 0 0 30px rgba(0, 0, 0, .2);
        }
        &__p5 {
            background: rgb(24,86,138);
            background: linear-gradient(0deg, rgba(24,86,138,1) 0%, rgba(11,102,176,1) 42%, rgba(41,111,170,1) 100%);
            width: 25%;
            height: 16%;
            bottom: -8%;
            right: -6%;
            transform: rotate(5deg);
            border-top: 1px solid #FFF5;
            border-left: 1px solid #0001;
            box-shadow: 0 0 10px rgba(0, 0, 0, .4);
        }
        &__p6 {
            background: rgb(41,51,78);
            background: linear-gradient(0deg, rgba(41,51,78,1) 0%, rgba(54,61,79,1) 42%, rgba(98,106,128,1) 100%);
            width: 15%;
            height: 15%;
            bottom: -10%;
            right: -6%;
            transform: rotate(-5deg);
            border-top: 1px solid #FFF5;
            border-left: 1px solid #0001;
            box-shadow: 0 0 30px rgba(0, 0, 0, .4);
        }
    }
`
