import React from 'react'
import Skills from './Skills/Skills'
import MiniAbout from './MiniAbout/MiniAbout'
import Logo from './Logo'
import GlobalStyledP4F from './AppStyled'
import SideFragments from './SideFragments/SideFragments'
import JobCards from './JobsCards/JobCards'
import SocialMedia from './SocialMedia/SocialMedia';

const jobs = [
  {
      title: 'SR/PL/JR Java Engineer',
      description: 'A Protonss4Fun está em busca de Java Engineers...'
  },
  {
      title: 'SR/PL/JR DevOps',
      description: 'A Protonss4Fun está em busca de DevOps...'
  },
  {
      title: 'SR/PL/JR Frontend Engineers',
      description: 'A Protonss4Fun está em busca de Frontend Engineers...'
  },
  {
      title: 'SR/PL/JR Fullstack Engineers',
      description: 'A Protonss4Fun está em busca de Fullstack Engineers...'
  },
  {
      title: 'SR/PL/JR QA',
      description: 'A Protonss4Fun está em busca de QA...'
  },
]

function App() {

  return (
    <>
      <GlobalStyledP4F />
      <main className="p4f">
        <section className="p4f__home">
          <Logo />

          <Skills options={['Inovação', 'Agilidade', 'Segurança', 'Tecnologia']} timer={3000} />

          <MiniAbout>
            <h1>
              Desenvolvendo soluções inovadoras para os segmentos de
              <strong>e-commerce, fidelização e  pagamentos.</strong>
            </h1>
          </MiniAbout>

          <SocialMedia />

          <SideFragments />
        </section>

        {/* <section className="p4f__jobs">
          <JobCards jobs={jobs} />
        </section> */}

      </main>
    </>
  )
}

export default App
