import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'

function Skills(props) {
    const { options } = props
    const [ skillsState, skillsSetState ] = useState({currentSkill: options[0]}, [])
    const { currentSkill } = skillsState
    const count = options.indexOf(currentSkill) < options.length - 1 ? options.indexOf(currentSkill) + 1 : 0
    setTimeout(() => {
        skillsSetState({
            ...skillsState,
            currentSkill: options[count]
        })
    }, props.timer)

    return (
        <SkillsStyled className="p4f__home__title-background" timer={props.timer}>
            <h2>{currentSkill}</h2>
            <p className="left">{currentSkill}</p>
            <p className="right">{currentSkill}</p>
        </SkillsStyled>
    )
}

export default Skills

const animateH2 = keyframes` 
    0%, 5% {
        opacity: 0;
        transform: translateY(-4%);
    }
    30% {
        opacity: 1;
    }
    85% {
        opacity: 1;
    }
    95% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        transform: translateY(4%);
    }
`
const animateLeft = keyframes` 
    0% {
        opacity: 0;
        transform: translateX(-5%) translateY(-5%);
    }
    30% {
        opacity: 1;
    }
    85% {
        opacity: 1;
    }
    95% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        transform: translateX(5%)  translateY(5%);
    }
`
const animateRight = keyframes` 
    0% {
        opacity: 0;
        transform: translateX(5%) translateY(5%);
    }
    30% {
        opacity: 1;
    }
    85% {
        opacity: 1;
    }
    95% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        transform: translateX(-5%)  translateY(-5%);
    }
`

const SkillsStyled = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    text-transform: uppercase;

    h2 {
        font-size: 9em;
        color: #FFF;
        text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
        position: relative;
        z-index: 2;
        animation: ${animateH2} ${props => props.timer / 1000}s linear infinite;
        opacity: 0;
    }
    .left,
    .right {
        position: absolute;
        color: rgba(0, 0, 0, 0.0);
    }
    .left {
        margin-top: 4%;
        left: 2%;
        font-size: 15em;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.04);
        animation: ${animateLeft} ${props => props.timer / 1000}s linear infinite;
        opacity: 0;
    }
    .right {
        margin-top: -5%;
        right: 2%;
        font-size: 14em;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.03);
        animation: ${animateRight} ${props => props.timer / 1000}s linear infinite;
        opacity: 0;
    }
`