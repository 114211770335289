import React from 'react'
import styled from 'styled-components'
import instagram from '../../media/img/instagram-protons4fun.svg'
import linkedin from '../../media/img/linkedin-protons4fun.svg'

function SocialMedia(props) {

    return (
        <SocialMediaStyled className="p4f__home__social-media">
            <a className="p4f__home__social-media__instagram" href="https://www.instagram.com/protonss4fun.lab/" target="_blank" title="Siga-nos no Instagram @protonss4fun">
                <img src ={instagram} alt="Siga-nos no Instagram @protonss4fun.lab" />
                Follow Us
            </a>
            <a className="p4f__home__social-media__linkedin" href="https://www.linkedin.com/company/protonss4fun/"  target="_blank" title="Faça parte da P4F">
                Join Us
                <img src ={linkedin} alt="Faça parte da P4F" />
            </a>
        </SocialMediaStyled>
    )
}

export default SocialMedia

const SocialMediaStyled = styled.div`
    display: flex;
    justify-content: center;
    padding: 2em;
    position: relative;
    z-index: 10;
    
    a {
        display: flex;
        padding: 0.1em 1em;
        align-items: center;
        font-size: 1.8em;
        font-family: 'Montserrat', sans-serif;
        cursor: pointer;
        color: #FFF;
        text-decoration: none;
        text-transform: uppercase;
        text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);

        &:hover {
            text-shadow: -1px -1px 2px rgba(0, 0, 0, 0.3);
            
            img {
                transform: scale(1.1);
            }
        }

        img {
            display: block;
            width: 1.2em;
            margin: 0.3em;
            transition: all 0.3s;
        }
    }

    .p4f__home__social-media {
        
        &__instagram {
            position: relative;

            &:after {
                content: " ";
                width: 3px;
                height: 60%;
                display: block;
                position: absolute;
                right: 0;
                top: 20%;
                background-color: #fff;
            }
        }
    }
`
