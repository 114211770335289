import React from 'react'
import styled, { keyframes } from 'styled-components'

function MiniAbout(props) {

    return (
        <MiniAboutStyled className="p4f__home__mini-about">
            {props.children}
        </MiniAboutStyled>
    )
}

export default MiniAbout
const textEntry = keyframes`
    from {
        opacity: 0;
        transform: translateY(30%);
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
`
const MiniAboutStyled = styled.div`
    text-align: center;
    font-family: 'Open Sans', sans-serif;

    h1 {
        padding-bottom: 2em;
        color: #4d5a5d;
        font-weight: normal;
        animation: ${textEntry} 2s linear 1s forwards;
        opacity: 0;
        position: relative;
        z-index: 4;
        
        strong {
        display: block;
        text-transform: uppercase;
        font-weight: bold;
        }
    }
`
